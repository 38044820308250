import {
  saveUser, saveToken, destroyToken, destroyUser,
} from '@/services/jwt.service';
import ApiService from '@/services/api.service';
import { clearStorage } from '@/services/localstorage.service';

import { getUserModules } from '@/lib/user';

export default {
  mResetModelAndDataset(state) {
    state.model = null;
    state.dataset = null;
  },
  mSetStep(state, step) {
    state.step = step;
  },
  mSetModel(state, model) {
    state.model = model;
  },
  mSetDisplay(state, display) {
    state.display = display;
    console.log('display', display);
  },
  mUpdateDataset(state, dataset) {
    // console.log('mSetDataset', dataset);
    const d = { ...dataset };
    const tableName = dataset.table_name ? dataset.table_name : '';
    d.uiKey = tableName + dataset.code + new Date().toString();
    const model = { ...state.model };
    const index = model.datasets.findIndex((item) => item.code === dataset.code);
    model.datasets[index] = d;
    state.model = model;
  },
  mSetLastCode(state, code) {
    state.lastCode = code;
  },
  mSetDataset(state, dataset) {
    console.log('mSetDataset', dataset);
    const d = { ...dataset };
    d.uiKey = dataset.code + new Date().toString();

    const model = { ...state.model };
    const index = model.datasets.findIndex((item) => item.code === dataset.code);
    model.datasets[index] = d;
    state.dataset = null;
    state.dataset = d;
    state.model = model;
    console.log('mSetDataset', d);
  },
  mSetError(state, error) {
    state.errors = error;
  },
  mSetAuth(state, user) {
    state.isAuthenticated = true;
    state.user = getUserModules(user);
    state.errors = {};
    saveUser(state.user);
    saveToken(state.user.token);
  },
  mPurgeAuth(state) {
    this._vm.$log.debug('mPurgeAuth');
    state.isAuthenticated = false;
    state.user = {};
    state.errors = {};
    destroyToken();
    destroyUser();
    // clearStorage();
    ApiService.removeHeader('Authorization');
    // this will reset wallet back to public
  },
  mClearStorage() {
    clearStorage();
  },
  mSetLoading(state, status) {
    state.loading = status;
  },
  mSetModels(state, models) {
    state.models = models;
  },
};
