/* eslint-disable no-undef */
import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import { API_URL } from '@/config';
import { getToken } from './jwt.service';
// import DomainService from "./domain.service";

const ApiService = {
  init() {
    Vue.use(VueAxios, axios);
    Vue.axios.defaults.baseURL = API_URL;
    Vue.axios.interceptors.response.use((response) => response, (error) => {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        if (error.response.status === 403) {
          try {
            // JwtService.destroyToken();
          } catch (err) {
            //
          }
        }
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        // console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
      }

      return Promise.reject(error);
    });
  },
  getBaseUrl() {
    return API_URL;
  },
  getHeader() {
    return {
      // Authorization: `Bearer ${getToken()}`,
    };
  },
  removeHeader(key) {
    try {
      delete Vue.axios.defaults.headers.common[key];
    } catch (error) {
      //
    }
  },
  setHeader() {
    // console.log("setHeader");
    Vue.axios.defaults.headers.common.Authorization = `Bearer ${getToken()}`;
  },
  query(resource, params) {
    return Vue.axios.get(resource, params);
  },

  get(resource, slug = '') {
    return Vue.axios.get(`${resource}/${slug}`).catch((error) => {
      throw new Error(`[RWV] ApiService ${error}`);
    });
  },
  post(resource, params) {
    return Vue.axios.post(`${resource}`, params);
  },

  update(resource, slug, params) {
    return Vue.axios.patch(`${resource}/${slug}`, params);
  },
  put(resource, params) {
    return Vue.axios.put(`${resource}`, params);
  },

  patch(resource, params) {
    return Vue.axios.patch(`${resource}`, params);
  },

  delete(resource) {
    return Vue.axios.delete(resource).catch((error) => {
      throw new Error(`[RWV] ApiService ${error}`);
    });
  },
};

export default ApiService;
export { API_URL };
