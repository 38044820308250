import {
  saveUser, saveToken, destroyToken, destroyUser,
} from '@/services/jwt.service';
import ApiService from '@/services/api.service';
import { clearStorage } from '@/services/localstorage.service';

import { getUserModules } from '@/lib/user';


export default {
  mSetError(state, error) {
    state.errors = error;
  },
  mSetAuth(state, user) {
    state.isAuthenticated = true;
    state.user = getUserModules(user);
    state.errors = {};
    saveUser(state.user);
    saveToken(state.user.token);
  },
  mPurgeAuth(state) {
    this._vm.$log.debug('mPurgeAuth');
    state.isAuthenticated = false;
    state.user = {};
    state.errors = {};
    destroyToken();
    destroyUser();
    // clearStorage();
    ApiService.removeHeader('Authorization');
    // this will reset wallet back to public
    this.commit('users/mSetWallets', null);
  },
  mClearStorage() {
    clearStorage();
  },
};
