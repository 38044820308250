import Vue from 'vue';
import Router from 'vue-router';

import {
  store,
} from './store/store';

Vue.use(Router);

const invoiceAdminRoutes = [
  {
    path: "",
    name: "invoices-admin-index",
    component: () => import("./views/admin/invoices"),
  },
  {
    path: "new-invoice/:code",
    name: "invoices-admin-new-invoice",
    component: () => import("./views/admin/new-invoice"),
  },
];

const adminRoutes = [
  {
    path: "invoices/:number",
    name: "invoices",
    component: () => import("./views/admin/invoices"),
  },
  {
    path: "",
    name: "companies",
    component: () => import("./views/admin/companies"),
  },
  {
    path: "invoice/:number",
    name: "invoice",
    component: () => import("./views/admin/invoice"),
  },
  // {
  //   path: "dashboard",
  //   name: "admin-dashboard",
  //   component: () => import("./views/invoice/dashboard.vue"),
  // },
];

const publicRoutes = [
  {
    path: '',
    meta: {
      requiresAuth: false,
    },
    name: 'model-builder-home',
    component: () => import("./views/admin/invoices"),
  },
];

const mainRoutes = [
  {
    path: '/',
    meta: {
      requiresAuth: false,
    },
    component: () => import('./layouts/admin/admin'),
    children: [
      ...publicRoutes,
    ],
  },
  {
    path: '/admin/invoice',
    meta: {
      requiresAuth: false,
    },
    component: () => import('./layouts/admin/invoice'),
    children: [
      ...invoiceAdminRoutes,
    ],
  },
  {
    path: '/admin/vat-bridge',
    meta: {
      requiresAuth: false,
    },
    component: () => import('./layouts/admin/admin'),
    children: [
      ...adminRoutes,
    ],
  },
  {
    path: '/login',
    meta: {
      requiresAuth: false,
    },
    name: 'login',
    component: () => import('./views/login-test'),
  },
  {
    name: 'HomeRedirect',
    path: '/',
    redirect: '/admin/invoices',
  },
  {
    path: '*',
    redirect: '/',
  },
];

const routes = [
  ...mainRoutes,
];

const router = new Router({
  mode: 'history',
  base: '/',
  routes,
  scrollBehavior() {
    const timeout = setTimeout(() => {
      const preloader = document.getElementById('preloader');
      if (preloader !== null || undefined) {
        preloader.remove();
      }
      clearTimeout(timeout);
    }, 500);
    return {
      x: 0,
      y: 0,
    };
  },
});

const checkLogin = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    // if (store.getters.can('read', to.name)) {
    next();
    return;
    // }
    // console.log('backToIndex');
    // router.push('/');
    // return;
  }

  const {
    path,
    query,
  } = to;
  // console.log('performLogin');
  store.dispatch('performLogin', {
    path,
    query,
  });
};

router.beforeEach(checkLogin);

export default router;
